var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactCust"},[_c('div',{staticClass:"pub-tit0"},[_vm._v("热门问题")]),_c('div',{staticClass:"qTop"},[_c('div',{staticClass:"questionBox"},_vm._l((_vm.questionList.slice(
          0,
          Math.ceil(_vm.questionList.length / 2)
        )),function(item){return _c('div',{key:item,staticClass:"qItem",on:{"click":function($event){return _vm.gohelpDetail(item)}}},[_c('div',{staticClass:"tit"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"btn"},[_vm._v(" 查看 "),_c('svg-btn',{staticClass:"svgBtn",attrs:{"icon-class":"icon-to"}})],1)])}),0),_c('div',{staticClass:"questionBox"},_vm._l((_vm.questionList.slice(
          Math.ceil(_vm.questionList.length / 2),
          _vm.questionList.length
        )),function(item){return _c('div',{key:item,staticClass:"qItem",on:{"click":function($event){return _vm.gohelpDetail(item)}}},[_c('div',{staticClass:"tit"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"btn"},[_vm._v(" 查看 "),_c('svg-btn',{staticClass:"svgBtn",attrs:{"icon-class":"icon-to"}})],1)])}),0)]),_c('div',{staticClass:"pub-tit0",staticStyle:{"margin":"52px 0 20px 0"}},[_vm._v("其他帮助")]),_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('img',{attrs:{"src":_vm.$utils.getPng('icon_help' + item.id),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"btn pointer",on:{"click":function($event){return _vm.toLink(item)}}},[_vm._v(" "+_vm._s(item.name1)+" ")])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }