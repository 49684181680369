<!--
 * @FilePath: /daren-html-source/src/views/Help/discountCode.vue
 * @Description:  优惠码组件
-->
<template>
  <div class="discountCode">
    <div class="pub-tit0">什么是优惠码？</div>
    <div class="intrBox">
      <div class="one">
        好友通过您的优惠码开通会员享折扣，同时您可获得高额返利；达人等级越高，优惠码折扣越大。
      </div>
    </div>
    <div class="pub-tit0" style="margin: 52px 0 20px 0">达人等级</div>
    <LevelTabe></LevelTabe>
  </div>
</template>

<script>
import LevelTabe from "@/components/LevelTable/index.vue";
export default {
  components: {
    LevelTabe,
  },
};
</script>

<style lang="scss" scoped>
.discountCode {
  padding-bottom: 100px;
}
</style>
