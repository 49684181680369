<!--
 * @FilePath: /daren-html-source/src/views/OfflineRebate/InvitationLink.vue
 * @Description: 邀请注册链接
-->
<template>
  <div>
    <div class="pub-tit0">
      好友通过邀请链接注册后成为飞猫达人，您可获得好友收益的高额返利。
    </div>
    <div class="pub-tit2 tit2">
      <img class="icon" :src="$utils.getPng('icon_link')" alt="" />
      <div class="title2">我的邀请链接</div>
    </div>
    <div class="inviteLink">
      <div class="linkBox">
        {{ inviteData.link }}
      </div>
      <div class="copyBtn pointer" @click="copyBtn(inviteData.link)">复制</div>
    </div>
    <img class="line" :src="$utils.getPng('icon_line')" alt="" />

    <div class="inviteCodeBox">
      <div class="codeBox">
        <div class="codeTit">我的邀请码：</div>
        <div class="codeBottom">
          <div class="codeNum" :class="inviteData.is_old_code == 1 ? 'oldNum' : ''">{{ inviteData.qrcode }}</div>
          <div class="codeBtn pointer" @click="copyBtn(inviteData.qrcode)">
            复制
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="qrcodeBox">
        <div class="codeTit">我的二维码：</div>
        <div class="qrBox" id="qrcode" ref="qrcode"></div>
        <div class="codeBtn" @click="saveFile()">保存</div>
      </div>
    </div>
    <div style="padding-top: 20px">
      <MemRbCom  :memberList="memberList"/>
    </div>
    <TableVue
      ref="tableVue"
      :tableTitle="tableTitle"
      :tableHeader="['用户昵称', '邀请渠道', '返利金额']"
      :changePageF="changePageF"
      :tableList="inviteNumPeopleList"
      :total="total"
    ></TableVue>
  </div>
</template>

<script>
import MemRbCom from "@/components/MemRbCom/index.vue";
import TableVue from "@/components/TablePublic/index.vue";
import {
  invitedDiscountCodeAPI,
  inviteNumPeopleAPI,
} from "@/utils/apiList/offlineRebate";
import qrcode from "qrcode";

export default {
  components: {
    MemRbCom,
    TableVue,
  },
  data() {
    return {
      inviteData: [],
      inviteNumPeopleList: [],
      total: 0,
      page: 1,
      memberList:[]
    };
  },
  computed: {
    tableTitle() {
      return `邀请人数（${this.total}）`;
    },
  },
  created() {
    this.invite();
  },
  mounted() {
    this.getinviteNumPeopleList();
  },
  methods: {
    invite() {
      invitedDiscountCodeAPI().then((res) => {
        if (res.status == 1) {
          this.inviteData = res.data.invited;
          this.createCode(res.data.invited.linkcode);
        }
      });
    },

    //生成支付二维码
    async createCode(code) {
      this.$nextTick(function () {
        var qr = qrcode(0, "L");
        // 2.添加二维码信息。
        qr.addData(code);
        // 3.生成二维码对象（并不显示）。
        qr.make();
        document.getElementById("qrcode").innerHTML = qr.createImgTag(4, 0);
      });
    },
    copyBtn(url) {
      this.$copyText(url).then(
        (e) => {
          this.$toast("复制成功", 1);
        },
        function (e) {
          this.$toast("复制失败", 2);
        }
      );
    },
    saveFile() {
      var parent = document.getElementById("qrcode");
      var child = parent.getElementsByTagName("img");
      var src = child[0].currentSrc;

      var $a = document.createElement("a");
      $a.setAttribute("href", src);
      $a.setAttribute("download", "下载.png"); // Set the filename with extension
      var evObj = document.createEvent("MouseEvents");
      evObj.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        true,
        false,
        0,
        null
      );
      $a.dispatchEvent(evObj);
    },
    getinviteNumPeopleList(page = 1) {
      this.$refs.tableVue.tableLoading = true;
      inviteNumPeopleAPI({ page: page, limit: 20 }).then((res) => {
        if (res.status == 1) {
          this.$refs.tableVue.tableLoading = false;
          this.total = parseInt(res.data.invited_count);
          this.inviteNumPeopleList = res.data.invited_list;
          this.memberList = res.data.amount_count
        }
      });
    },
    changePageF(page) {
      this.page = page;
      this.getinviteNumPeopleList();
    },
  },
};
</script>

<style lang="scss" scoped>
.tit2 {
  margin-top: 24px;
}
.inviteLink {
  display: flex;
  margin-top: 8px;
  .linkBox {
    width: 776px;
    height: 48px;
    background: #f9f9f9;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 20px;
    color: #8a8b92;
  }
  .copyBtn {
    width: 120px;
    height: 48px;
    background: linear-gradient(164deg, #ff9e41 0%, #ff7031 100%);
    box-shadow: 0px 10px 14px -1px rgba(246, 122, 66, 0.2);
    border-radius: 24px;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    margin-left: 24px;
  }
}
.line {
  width: 932px;
  height: 2px;
  margin: 31px 0;
}
.inviteCodeBox {
  width: 932px;
  height: 160px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  .codeBox {
    display: flex;
    flex-direction: column;
    width: 386px;
    height: 125px;
    justify-content: space-between;

    .codeBottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .codeNum {
        height: 48px;
        font-weight: 600;
        font-weight: 500;
        font-size: 32px;
        color: #f67942;
        line-height: 48px;
        letter-spacing: 2px;
      }
      .oldNum{
        font-size: 28px;
        letter-spacing: 0;
      }
      .codeBtn {
        width: 76px;
        height: 32px;
        background: linear-gradient(164deg, #ff9e41 0%, #ff7031 100%);
        box-shadow: 0px 10px 14px -1px rgba(246, 122, 66, 0.2);
        border-radius: 18px;
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        line-height: 32px;
      }
    }
  }
  .line {
    width: 1px;
    height: 64px;
    background: #e5e5e5;
    margin: 0 56px;
  }
  .codeTit {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    color: #2c2c2c;
    line-height: 24px;
  }
  .codeBtn {
    width: 76px;
    height: 32px;
    background: linear-gradient(164deg, #ff9e41 0%, #ff7031 100%);
    box-shadow: 0px 10px 14px -1px rgba(246, 122, 66, 0.2);
    border-radius: 18px;
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    line-height: 32px;
  }

  .qrcodeBox {
    display: flex;
    .qrBox {
      width: 112px;
      height: 112px;
      background: #ffffff;
      box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
      border-radius: 7px;
      border: 1px solid #e5e5e5;
      margin: 0 37px 0 32px;
    }
    .codeBtn {
      margin-top: auto;
      margin-right: 0;
    }
  }
}
</style>
