<!--
 * @FilePath: /daren-html-source/src/views/Help/index.vue
 * @Description: 帮助中心
-->

<template>
  <div class="memberRebate min-height">
    <div class="mTop">
      <div
        v-for="item in topData"
        :key="item.id"
        class="mTopBtn"
        :class="{ act: topActive == item.id }"

        @click="topClick(item.id)"
        >{{ item.name }}</div
      >
    </div>
    <div class="memberContent">
      <FmpTab v-if="topActive == 0"></FmpTab>
      <discountTab v-if="topActive == 1"></discountTab>
      <RebateTab v-if="topActive == 2"></RebateTab>
      <contactCustTab v-if="topActive == 4"></contactCustTab>
    </div>
  </div>
</template>

<script>
import FmpTab from "./fmp.vue";
import discountTab from "./discountCode.vue";
import RebateTab from "./rebate.vue";
import contactCustTab from "./contactCust.vue";
export default {
  components: {
    FmpTab,
    discountTab,
    RebateTab,
    contactCustTab,
  },
  data() {
    return {
      topData: [
        { id: 0, name: "飞猫盘" },
        { id: 1, name: "优惠码" },
        { id: 2, name: "下线返利" },
        { id: 3, name: "意见箱" },
        { id: 4, name: "联系客服" },
      ],
      topActive: 0,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.topActive = Number(this.$route.query.id) || 0;
    }
  },
  methods: {
    topClick(id) {
      if (id == 3) {
        window.open(`https://jinshuju.net/f/eykqQ2`, "_blank");
        return;
      }

      this.topActive = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.memberRebate {
  background: #f7f7f7;

  .memberContent {
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    padding: 32px 24px;
    min-height: calc(100vh - 50px - 20px - 30px - 36px - 18px);
  }
}
</style>
