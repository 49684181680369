<!--
 * @FilePath: /daren-html-source/src/components/NavTop/index.vue
 * @Description: 顶部菜单栏
-->
<template>
  <div class="navTop">
    <div class="center">
      <img
        class="logo pointer"
        @click="tohome"
        :src="$utils.getPng('logo')"
        alt=""
      />
      <div class="loginInfo" v-if="fmCookieName">
        <div class="userGen">
          <img :src="$store.state.userInfo.logo" alt="" />
          <span>{{ $store.state.userInfo.nickname }}</span>
        </div>
        <div class="general" @click="isShowuserNews = true">
          <el-badge
            :max="99"
            :value="$store.state.userMsgNub"
            :hidden="$store.state.userMsgNub == 0"
          >
            <svg-btn class="logo1" icon-class="icon-general"></svg-btn>
          </el-badge>
        </div>
        <div class="user2"></div>
        <div class="loginOut">
          <div class="one pointer" @click="loginoutClick(1)">
            <img class="imggr" :src="$utils.getPng('icon_22')" alt="" />
            <div class="ontit">个人账户</div>
          </div>
          <div class="one one2 pointer" @click="loginoutClick(2)">
            <img class="imggr" :src="$utils.getPng('icon_21')" alt="" />
            <div class="ontit">退出</div>
          </div>
        </div>
      </div>

      <div class="loginbtn" v-else>
        <fm-button
          type="primary"
          round
          size="medium"
          style="margin: 0 16px 0 24px"
          @click="toLogin()"
          >登 录</fm-button
        >
        <fm-button type="info" round size="medium" @click="toLogin('register')"
          >注 册</fm-button
        >
        <span class="divide"> </span>
      </div>
      <userNews
        :isShow="isShowuserNews"
        @handleClose="isShowuserNews = false"
      ></userNews>
    </div>
  </div>
</template>

<script>
import {
  userInfoAPI,
  userMsginfoapi,
  loginOutAPI,
} from "@/utils/apiList/user.js";
import userNews from "@/components/userNews/index.vue";

export default {
  data() {
    return {
      fmCookieName: this.$utils.getCookie(this.$glb.fmCookieName),
      isShowuserNews: false,
    };
  },
  components: {
    userNews,
  },
  created() {
    this.getUser();
    this.userMsginfoapiFn();
  },
  mounted() {
    if (this.$utils.getCookie(this.$glb.fmCookieName)) {
      localStorage.setItem(
        "darenToken",
        this.$utils.getCookie(this.$glb.fmCookieName)
      );
    }
    this.$store.commit("setAttr", {
      name: "getUserInfo",
      val: this.getUser,
    });
    this.$store.commit("setAttr", {
      name: "getNewCookie",
      val: this.getNewCookie,
    });
    document.addEventListener("visibilitychange", this.handleVisiable);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
  methods: {
    getNewCookie() {
      this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName)
      console.log('getNewCookie', this.fmCookieName);
    },
    toLogin(item) {
      let path = this.$route.path.toString().slice(1);
      const from = "daren";
      const fromUrl = this.$glb.darenUrl + `/${path}`;
      const encodedFrom = encodeURIComponent(from);
      const encodedFromUrl = encodeURIComponent(fromUrl);
      if (item == "register") {
        let query = {
          path: "drive",
          tab: "2",
        };

        // this.$router.push({ path: "/login", query });
        window.location.href =
          this.$glb.fmpUrl +
          `/login?path=drive&tab=2&from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
      } else {
        // this.$router.push({ path: "/login", query: { path: path } });
        window.location.href =
          this.$glb.fmpUrl +
          `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
      }
      return;
    },
    getUser() {
      userInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.$store.commit("setAttr", {
              name: "userInfo",
              val: res.data,
            });
            localStorage.setItem("isDaren", res.data.is_rate_flow);
          } else {
            this.$toast(res.msg, 3);
          }
        })
        .catch((err) => {});
    },
    userMsginfoapiFn() {
      userMsginfoapi()
        .then((res) => {
          this.$store.commit("setAttr", {
            name: "userMsgNub",
            val: +res.data.count || 0,
          });
        })
        .catch((err) => {});
    },
    loginoutClick(index) {
      index == 1 ? this.$router.push({ path: "/account" }) : this.loginOut();
    },
    loginOut() {
      loginOutAPI()
        .then((res) => {
          this.$utils.clearCookie(this.$glb.fmCookieName);
          this.$store.commit("setAttr", {
            name: "userInfo",
            val: {},
          });
          this.$toast("请先登录", 3);
          localStorage.removeItem("isDaren");
          this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName);
          this.$router.push({ path: "/llz" });
        })
        .catch((err) => {});
    },
    tohome() {
      this.$router.push({ path: "/drive" });
    },
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        case "prerender":
          // console.log("网页预渲染，内容不可见");
          break;
        case "hidden":
          // console.log("内容不可见，处理后台、最小化、锁屏状态");
          break;
        case "visible":
          // console.log("visible");
          this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName)
          if (
            this.$utils.getCookie(this.$glb.fmCookieName) &&
            this.$utils.getCookie(this.$glb.fmCookieName) !=
              localStorage.getItem("darenToken")
          ) {
            userInfoAPI()
              .then((res) => {
                if (res.status == 1) {
                  this.$store.commit("setAttr", {
                    name: "userInfo",
                    val: res.data,
                  });
                  localStorage.setItem("isDaren", res.data.is_rate_flow);
                  if (res.data.is_rate_flow == 1) {
                    this.$router.push({ path: "/drive" });
                  } else {
                    this.$router.push({ path: "/llz" });
                    sessionStorage.setItem("isFirst", 1);
                  }
                } else {
                  this.$toast(res.msg, 3);
                }
              })
              .catch((err) => {});
          }

          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
