/*
 * @FilePath: /www.feimaoyun.com_html_reconfiguration/src/utils/secretkey/pfile.js
 * @Description: 订阅号获取列表的参数的加密密钥
 */
export default `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApkEFC8tcLCKGX/BhQ6bp
YUN6bJCB7uYG8qGvjudOuKw8B53BXGYbBm5gQgcv0IVmfL8qkbRahC2JkvzLKRMw
+xM3ZkoWwinPEYt8ni34+bv0HwsEXh662TTYgJSJDPB/XP2X8p2OEY84ZnUc3XGo
9gxI6NdlgpLFG08oLqA1zlf5A2/QNAZqzJoSSaNGTPGXw4BHuqdvmOmHuCh1wDEi
FKcLgww0ns4cEB28x4lUAA4z18Fk1e30CVAzz3BABi01Ay5EJbB45StGYyma9R7R
WsJrptt/RLsc1RmkX9jq/LjCC4dxy3hWsLwxb7eMzpTvErhpUmQZ0bDS8Vpo9jkz
tQIDAQAB`
