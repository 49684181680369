<template>
  <div class="paginationbox" v-show="total > pageSize">
    <el-pagination
      :style="{ 'margin-top': margin + 'px', 'margin-bottom': margin + 'px' }"
      @current-change="handleCurrentChange"
      :current-page.sync="pg"
      :pager-count="pagerCount"
      :page-size="pageSize"
      prev-text="上一页"
      next-text="下一页"
      layout=" prev, pager, next"
      :total="total"
    >
    </el-pagination>
    {{ page }}
    <div class="jumper">
      <span>到</span>
      <el-input
        v-model="input"
        type="number"
        οnkeyup="value=value.replace(/[^\d]/g,'')"
        placeholder=""
        @keyup.enter.native="gopage()"
      ></el-input>
      <span>页</span>
      <fm-button class="btn" type="primary" size="mini" @click="gopage"
        >确 定</fm-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 100,
    },
    margin: {
      type: Number,
      default: 10,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      input: 1,
      pg: 1,
    };
  },
  created() {},
  methods: {
    handleCurrentChange(item) {
      this.$emit("changepage", item);
      this.pg = item;
      this.input = item;
    },
    gopage() {
      if (parseInt(this.input) > 0) {
      } else {
        this.input = 1;
      }

      this.input = this.input < 1 ? 1 : parseInt(this.input);
      this.pg = this.input;
      const num = Math.ceil(this.$props.total / this.$props.pageSize);
      if (this.input > num) {
        this.input = num;
      }

      this.$emit("changepage", this.input);
    },
    setPage(page) {
      this.pg = page;
      this.input = page;
    },
  },
};
</script>
<style>
.el-input__inner[type="number"] {
  -moz-appearance: textfield;
}
.el-input__inner[type="number"]::-webkit-inner-spin-button,
.el-input__inner[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style lang="scss" scoped>
.paginationbox {
  display: flex;
  align-items: center;
  ::v-deep .el-pagination {
    height: 36px;
    padding: 0;
  }
  ::v-deep .btn-prev {
    width: 70px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 0;
    color: #2c2c2c;
    :hover {
      color: #f67942;
    }
  }
  ::v-deep .btn-next {
    width: 70px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 0;
    color: #2c2c2c;
    :hover {
      color: #f67942;
    }
  }
  ::v-deep .number {
    width: 36px;
    height: 36px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    margin: 0 5px;
    color: #2c2c2c;
    font-weight: 400;
    &:hover {
      background: #f67942;
      color: #fff;
    }
  }
  ::v-deep .el-pager li.active {
    background: #f67942;
    color: #fff;
  }
  .jumper {
    display: flex;
    align-items: center;
    height: 36px;
    font-weight: 400;
    font-size: 14px;
    color: #2c2c2c;
    line-height: 36px;
    margin-left: 20px;

    ::v-deep .el-input__inner {
      width: 52px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      background: #fff;
      margin: 0 10px;
    }
    .btn {
      margin-left: 10px;
    }
  }
}
</style>
