<!--
 * @FilePath: /daren-html-source/src/components/LevelTable/index.vue
 * @Description: 达人等级表格
-->
<template>
  <div class="levelTable" v-loading="loading">
    <div class="colum">
      <div class="colum0" style="color: #8a8b92; font-weight: 500">
        等级权益
      </div>
      <div
        class="colum0_1"
        style="color: #2c2c2c; font-weight: 500"
        v-for="item in plansList"
        :key="item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="colum" v-for="item in planInfoData.plan" :key="item.plan_id">
      <div class="colum0 colum1" style="color: #2c2c2c; font-weight: 400">
        <div class="titBox">
          <img class="icon" :src="item.icon" alt="" />
          <div class="one">{{ item.subject }}</div>
        </div>
      </div>
      <div class="colum0_1 colum1_1">{{ item.label }}</div>
      <div class="colum0_1 colum1_1">{{ item.high_income_over_price }}</div>
      <div class="colum0_1 colum1_1">{{ item.high_income_lower_price }}</div>
      <div class="colum0_1 colum1_1">{{ item.user_coupon_discount_rate }}</div>
      <div class="colum0_1 colum1_1">{{ item.user_coupon_rebate_rate }}</div>
    </div>
  </div>
</template>

<script>
import { planInfoAPI } from "@/utils/apiList/offlineRebate";

export default {
  data() {
    return {
      planInfoData: {},
      plansList: [
        { name: "昨日下载量", id: 0, val: [] },
        { name: "大文件返利（元/万次）", id: 1, val: [] },
        { name: "小文件返利（元/万次）", id: 2, val: [] },
        { name: "优惠码折扣", id: 4, val: [] },
        { name: "优惠码收益", id: 4, val: [] },
      ],
      loading: false,
    };
  },
  created() {
    this.getPlanInfo();
  },
  methods: {
    getPlanInfo() {
      this.loading = true;
      planInfoAPI().then((res) => {
        if (res.status == 1) {
          this.planInfoData = res.data;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.levelTable {
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  .colum {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    .colum0 {
      height: 106px;
      width: 212px;
      background: #f9f9f9;
      display: flex;
      color: #2c2c2c;
      align-items: center;
      padding-left: 24px;
      .titBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          width: 48px;
          margin-bottom: 4px;
        }
      }
    }
    .colum0_1 {
      width: 212px;
      height: 56px;
      color: #8a8b92;
      display: flex;
      align-items: center;
      padding-left: 24px;
      box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    }
    .colum1 {
      width: 120px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .colum1_1 {
      width: 120px;
      color: #8a8b92;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
}
</style>
