<!--
 * @FilePath: /daren-html-source/src/views/Subscribe/index.vue
 * @Description: 订阅号页面
-->
<template>
  <div class="subscribe">
    <div class="tit">
      订阅号已升级成橘猫订阅号独立运营，管理后台已迁移至新网址
    </div>
    <fm-button class="btn" iconLast="icon-to" round size="medium" @click="toSub"
      >访问橘猫订阅号·创作中心</fm-button
    >
  </div>
</template>

<script>
export default {
  methods: {
    toSub() {
      let _url = this.$glb.upJmdyhUrl + '/subscribe';
      let uInfo = this.$store.state.userInfo || {};
      if (uInfo.token) {
        _url += "?" + "platfro=" + uInfo.token;
      }
      window.open(_url);
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  background: #ffffff;
  height: calc(100vh - 50px - 20px - 30px);
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 280px;
  .tit {
    height: 32px;
    font-weight: 600;
    font-size: 24px;
    color: #2c2c2c;
    line-height: 32px;
  }
  .btn {
    width: 240px;
    height: 44px;
    background: linear-gradient(164deg, #ff9e41 0%, #ff7031 100%);
    box-shadow: 0px 10px 14px -1px rgba(246, 122, 66, 0.2);
    border-radius: 26px;
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
