var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"fm-button",class:[
    `fm-button-${_vm.type}`,
    `fm-button-${_vm.size}`,
    {
      'is-plain': _vm.plain,
      'is-round': _vm.round,
      'is-disabled': _vm.disabled,
      'is-dashed': _vm.dashed,
      'is-text': _vm.text,
      'is-icon': _vm.icon !== '' || _vm.iconLast !== '' ,
      'is-iconLast': _vm.iconLast !== '' ,
      'is-circle': _vm.circle,
      'icon-btn': !_vm.$slots.default,
    },
  ],on:{"click":_vm.handleClick}},[(_vm.icon)?_c('svg-btn',{attrs:{"icon-class":`${_vm.icon}`}}):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e(),(_vm.iconLast)?_c('svg-btn',{staticStyle:{"margin-left":"8px"},attrs:{"icon-class":`${_vm.iconLast}`}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }