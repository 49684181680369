<!--
 * @FilePath: /daren-html-source/src/views/Account/userInfo.vue
 * @Description: 账户信息
-->
<template>
  <div class="userInfo">
    <div class="top">
      <div class="left">
        <img class="logo" :src="userData.logo" alt="" />
        <div class="nickName">{{ userData.nickname }}</div>
      </div>

      <fm-button
        icon="icon5"
        class="kfbtn"
        type="primary"
        plain
        @click="tokefu()"
        >咨询客服了解更多</fm-button
      >
    </div>
    <div class="center">
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_user0`)" alt="" />
          <div class="name">联系手机</div>
        </div>

        <div class="intr">{{ $utils.hidePhone(userData.phone) }}</div>
        <div class="btnBox">
          <fm-button
            v-if="!loading"
            class="btn"
            size="medium"
            type="info"
            round
            plain
            @click="changePhone()"
          >
            {{ userData?.phone ? "修改绑定手机" : "绑定手机" }}</fm-button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_user1`)" alt="" />
          <div class="name">联系邮箱</div>
        </div>

        <div class="intr">{{ $utils.regEmail(userData.email) }}</div>
        <div class="btnBox">
          <fm-button
            v-if="!loading"
            class="btn"
            size="medium"
            @click="changeEmail()"
            type="info"
            round
            plain
            >{{ userData.email ? "修改绑定邮箱" : "绑定邮箱" }}</fm-button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_user2`)" alt="" />
          <div class="name">飞猫达人认证</div>
        </div>

        <div class="intr">
          {{ is_rate_flow == 1 ? "已认证飞猫达人" : "未认证飞猫达人" }}
        </div>
        <div class="btnBox1 ">
          <fm-button
            v-if="!loading"
            class="btn btn1"
            size="medium"
            :style="
              is_rate_flow == 1 ? ' color: #f67942;' : ''
            "
            :type="is_rate_flow == 1 ? 'text' : 'info'"
            round
            plain
            @click="is_rate_flow == 1 ? ' ' : toOpenDaren()"
            >{{ is_rate_flow == 1 ? "已认证" : "去认证" }}</fm-button
          >
        </div>
      </div>
      <div class="item">
        <div class="itemLeft">
          <img class="icon" :src="$utils.getPng(`icon_user3`)" alt="" />
          <div class="name">订阅号开通</div>
        </div>

        <div class="intr">
          {{ is_subscribe == 1 ? "已开通订阅号" : "未开通订阅号" }}
        </div>
        <div class="btnBox">
          <fm-button
            v-if="!loading"
            class="btn"
            size="medium"
            type="info"
            round
            plain
            @click="toSub()"
            >{{ is_subscribe == 1 ? "进入订阅号" : "去开通" }}</fm-button
          >
        </div>
      </div>
      <ChangePhoneDiaVue
        ref="ChangePhoneDia"
        :userInfo="userData"
        :showChangePhoneFlag="showChangePhoneFlag"
        @handleClose="showChangePhoneFlag = false"
      ></ChangePhoneDiaVue>
      <RealName
        :isShow="showRealNameFlag"
        :type="2"
        @handleClose="showRealNameFlag = false"
        @confirmNameAuth="realNameAuth"
      ></RealName>
      <ChangeEmail
        ref="ChangeEmailDia"
        :userInfo="userData"
        :showChangePhoneFlag="showChangeEmailFlag"
        @handleClose="showChangeEmailFlag = false"
      ></ChangeEmail>
      <OpenSubNoDaren
        :isShow="noDareFlag"
        @confirm="toOpenDaren()"
        @handleClose="noDareFlag = false"
      ></OpenSubNoDaren>
    </div>
  </div>
</template>

<script>
import ChangePhoneDiaVue from "@/components/ChangePhoneDIa/index.vue";
import RealName from "@/components/ChangePhoneDIa/realName.vue";
import ChangeEmail from "@/components/ChangeEmail/index.vue";
import OpenSubNoDaren from "@/components/OpenSubNoDaren/index.vue";

import { userInfoAPI, realNameAuth } from "@/utils/apiList/user.js";

export default {
  components: {
    ChangePhoneDiaVue,
    RealName,
    ChangeEmail,
    OpenSubNoDaren,
  },
  data() {
    return {
      userList: [
        { id: 0, name: "联系手机", intr: "", btnTit: "绑定手机" },
        { id: 1, name: "联系邮箱", intr: "", btnTit: "绑定邮箱" },
        {
          id: 2,
          name: "飞猫达人认证",
          intr: "未认证飞猫达人",
          btnTit: "去认证",
        },
        { id: 3, name: "订阅号开通", intr: "未开通订阅号", btnTit: "去开通" },
      ],
      userData: {},
      showChangePhoneFlag: false,
      showRealNameFlag: false,
      showChangeEmailFlag: false,
      noDareFlag: false,
      loading: false,
    };
  },
  computed: {
    is_rate_flow() {
      return this.userData.is_rate_flow;
    },
    is_subscribe() {
      return this.userData.is_subscribe;
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    changePhone() {
      if (this.userData.phone) {
        this.$refs.ChangePhoneDia.step = 1;
        this.$refs.ChangePhoneDia.topTit = "修改手机号";
      } else {
        this.$refs.ChangePhoneDia.step = 2;
        this.$refs.ChangePhoneDia.topTit = "绑定手机号";
      }
      this.showChangePhoneFlag = true;
    },
    changeEmail() {
      if (this.userData.email) {
        this.$refs.ChangeEmailDia.step = 1;
        this.$refs.ChangeEmailDia.topTit = "修改邮箱";
      } else {
        this.$refs.ChangeEmailDia.step = 2;
        this.$refs.ChangeEmailDia.topTit = "绑定邮箱";
      }
      this.showChangeEmailFlag = true;
    },
    getUser() {
      this.loading = true;
      userInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.userData = res.data;
            this.$store.commit("setAttr", {
              name: "userInfo",
              val: res.data,
            });
            this.loading = false;
            localStorage.setItem("isDaren", res.data.is_rate_flow);
          }
        })
        .catch((err) => {});
    },

    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.showRealNameFlag = false;
          this.getUser();
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    toOpenDaren() {
      this.$router.push({
        name: "llz",
        params: {
          flag: this.userData.phone == "" ? 2 : this.userData.is_bind_idcard,
        },
      });
    },
    toSub() {
      if (this.userData.is_rate_flow == 1) {
        let _url = this.$glb.upJmdyhUrl + "/subscribe";
        let uInfo = this.$store.state.userInfo || {};
        if (uInfo.token) {
          _url += "?" + "platfro=" + uInfo.token;
        }
        window.open(_url);
      } else {
        this.noDareFlag = true;
      }
    },
    tokefu() {
      window.open("http://q.url.cn/CDKEvl?_type=wpa&qidian=true");
    },
  },
};
</script>

<style lang="scss" scoped>
.userInfo {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  .top {
    display: flex;
    align-items: center;
    padding-top: 8px;
    .left {
      display: flex;
      align-items: center;
      height: 72px;

      width: 100%;
      .logo {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        object-fit: cover;
      }
      .nickName {
        height: 24px;
        font-weight: 500;
        font-size: 16px;
        color: #2c2c2c;
        line-height: 24px;
        margin-left: 16px;
      }
    }
    .kfbtn {
      border: 1px solid #f67942;
      width: 184px;
      height: 40px;
      border-radius: 10px;
      font-size: 14px;
    }
  }
  .center {
    margin-top: 64px;
    .item {
      display: flex;
      height: 88px;
      box-shadow: inset 0px -1px 0px 0px #f6f6f6;
      align-items: center;
      .itemLeft {
        display: flex;
        height: 88px;
        width: 136px;
        align-items: center;
        color: #2c2c2c;
        .icon {
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }
        .name{
          width: 88px !important;
        }
      }
      .intr {
        height: 22px;
        font-weight: 400;
        font-size: 14px;
        color: #8a8b92;
        line-height: 22px;
        margin-left: 172px;
      }
      .btnBox {
        margin-left: auto;
        margin-right: 0;
        .btn {
          width: 132px;
        }
        :hover {
          background: #f6f6f6;
        }
      }
      .btnBox1 {
        margin-left: auto;
        margin-right: 0;
        .btn {
          width: 132px;
          cursor: auto;
        }
        
      }
    }
  }
}
</style>
