<!--
 * @FilePath: /daren-html-source/src/views/OfflineRebate/offIncomeData.vue
 * @Description: 下线返利-收益数据
-->
<template>
  <div class="incomeData">
    <div class="incometop">
      <img class="icon_mem" :src="$utils.getPng('icon_rebate')" alt="" />
      <div class="info">
        <div class="iTit">下线返利计划</div>
        <div class="iIntr">
          用户通过邀请链接、邀请码、邀请二维码注册后开通飞猫达人，您可获得收益
        </div>
      </div>
      <div class="incomeRight">
        <fm-button class="infobtn" type="info" plain round @click="toplay"
          >查看玩法介绍</fm-button
        >
        <div class="line"></div>
        <div class="mylevel">
          我的等级：<span
            class="levelAct"
            :style="`color:#${$utils.getLevelColor(
              $store.state.userInfo.user_plan.subject
            )}`"
            >{{ $store.state.userInfo.user_plan.subject }}</span
          >
        </div>
      </div>
    </div>
    <MemRbCom title="下线返利收益" :memberList="memberList" />
    <TableVue
      ref="tableVue"
      :tableTitle="tableTitle"
      :tableHeader="['用户昵称', '邀请渠道', '返利金额']"
      :changepage="changepage"
      :tableList="inviteNumPeopleList"
      :total="total"
    ></TableVue>
  </div>
</template>

<script>
import MemRbCom from "@/components/MemRbCom/index.vue";
import TableVue from "@/components/TablePublic/index.vue";
import { inviteNumPeopleAPI } from "@/utils/apiList/offlineRebate";
export default {
  components: {
    MemRbCom,
    TableVue,
  },
  computed: {
    tableTitle() {
      return `邀请人数（${this.total}）`;
    },
  },
  data() {
    return {
      total: 0,
      inviteNumPeopleList: [],
      memberList:[]
    };
  },
  mounted() {
    this.getinviteNumPeopleList();
  },
  methods: {
    changepage() {
    },
    getinviteNumPeopleList(page) {
      this.$refs.tableVue.tableLoading = true;
      inviteNumPeopleAPI({ page: page, limit: 20 }).then((res) => {
        if (res.status == 1) {
          this.$refs.tableVue.tableLoading = false;
          this.total = parseInt(res.data.invited_count);
          this.inviteNumPeopleList = res.data.invited_list;
          this.memberList = res.data.amount_count
        }
      });
    },
    toplay() {
      this.$router.push({ path: "/help", query: { id: 2 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.incomeData {
  .incometop {
    width: 100%;
    box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    .icon_mem {
      width: 64px;
      height: 64px;
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      .iTit {
        height: 28px;
        font-weight: 600;
        font-size: 20px;
        color: #2c2c2c;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .iIntr {
        height: 22px;
        font-weight: 400;
        font-size: 14px;
        color: #8a8b92;
        line-height: 22px;
      }
    }
    .incomeRight {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 0;
      .infobtn {
        width: 96px;
        height: 28px;
        border-radius: 18px;
        border: 1px solid #f67942;
        font-weight: 500;
        font-size: 12px;
        color: #f67942;
      }
      .infobtn:hover {
        border: 1px solid #f67942 !important;
      }
      .line {
        width: 1px;
        height: 16px;
        background: #e5e5e5;
        margin: 0 12px;
      }
      .mylevel {
        height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #8a8b92;
        line-height: 20px;
        .levelAct {
          color: #f28c2a;
        }
      }
    }
  }
}
</style>
