import CryptoJS from "CryptoJS";
import JSEncrypt from "JSEncrypt";
import store from "@/store";
import { Message } from 'element-ui'

import conf from "./config/index";

var rsaUtil = {
  // RSA 位数  1024，这里要跟后端对应
  bits: 512,
  // 当前JSEncrypted对象
  thisKeyPair: {},
};
let is_event = false;
export default {
  // 获取aeskey，
  genak(length = 12) {
    let random =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str = str + random.charAt(Math.random() * random.length);
    }
    return str;
  },

  // aes加密
  ae(plaintext, key) {
    // if (plaintext instanceof Object) {
    // // JSON.stringify
    //   plaintext = JSON.stringify(plaintext)
    // }
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plaintext),
      CryptoJS.enc.Utf8.parse(key),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return encrypted.toString();
  },

  // aes 解密
  ad(ciphertext, key) {
    let decrypt = CryptoJS.AES.decrypt(
      ciphertext,
      CryptoJS.enc.Utf8.parse(key),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    let decString = CryptoJS.enc.Utf8.stringify(decrypt).toString();
    // if (decString.charAt(0) === '{' || decString.charAt(0) === '[') {
    // // JSON.parse
    //   decString = JSON.parse(decString)
    // }
    return decString;
  },

  // 生成rsa密钥对(公钥和私钥)
  genrp(bits = rsaUtil.bits) {
    let genKeyPair = {};
    rsaUtil.thisKeyPair = new JSEncrypt({ default_key_size: bits });
    // 获取私钥
    genKeyPair.privateKey = rsaUtil.thisKeyPair.getPrivateKey();
    // 获取公钥
    genKeyPair.publicKey = rsaUtil.thisKeyPair.getPublicKey();
    return genKeyPair;
  },
  // 公钥加密
  re(plaintext, publicKey) {
    // 由于没有genRsaKeyPair，所以需要执行下面这句
    rsaUtil.thisKeyPair = new JSEncrypt({ default_key_size: rsaUtil.bits });
    if (plaintext instanceof Object) {
      plaintext = JSON.stringify(plaintext);
    }

    publicKey && rsaUtil.thisKeyPair.setPublicKey(publicKey);
    return rsaUtil.thisKeyPair.encrypt(plaintext);
  },

  // 私钥解密
  rd(ciphertext, privateKey) {
    privateKey && rsaUtil.thisKeyPair.setPrivateKey(privateKey);
    let decString = rsaUtil.thisKeyPair.decrypt(ciphertext);
    // if (decString.charAt(0) === '{' || decString.charAt(0) === '[') {
    //   // JSON.parse
    //   decString = JSON.parse(decString)
    // }
    return decString;
  },
  // 加密解密方法：string-需要解密的字段  code-约定的密钥    operation 默认false表示加密，传入true表示解密
  secret(string, code, operation) {
    if (string instanceof Object) {
      // JSON.stringify
      string = JSON.stringify(string);
    }
    code = CryptoJS.MD5(code).toString();
    var iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
    var key = CryptoJS.enc.Utf8.parse(code.substring(16));
    if (operation) {
      return CryptoJS.AES.decrypt(string, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
    }
    return CryptoJS.AES.encrypt(string, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  },

  setCookie(name, value, second) {
    var timestr = "";
    if (second > 0) {
      var exp = new Date();
      exp.setTime(exp.getTime() + 1000 * second);
      timestr = ";expires=" + exp.toGMTString();
    }
    document.cookie = name + "=" + escape(value) + timestr;
  },
  getCookie(name) {
    var arrt = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]*)(;|$)")
    );
    if (arrt) {
      return unescape(arrt[2]);
    } else {
      return null;
    }
  },
  clearCookie(cname) {
    var cd = new Date();
    cd.setTime(cd.getTime() - 1);
    document.cookie = cname + "=-1;expires=" + cd.toUTCString();
    // window.document.cookie = cname + '=del;path=/;expires=-1'
  },
  getPng0(str, extension = 'png') {
    // 得到正确路径的图片
    return conf.cdnPic + "/fm2023/" + str + "." + extension;
  },
  getPng(str) {
    // 得到正确路径的图片
    return conf.cdnPic + "/fm2023/web4/daren/" + str + ".png";
  },

  hidePhone(phone) {
    if (!phone) return "";
    let str = phone;
    str = str.toString().replace(/^(.{3})(?:\w+)(.{2})$/, "$1******$2");
    return str;
  },
  hideIdcard(num) {
    if (!num) return '';
    const firstThree = num.slice(0, 3);
    const lastOne = num.slice(-1);
    const maskedMiddle = '*'.repeat(num.length - 4);

    return `${firstThree}${maskedMiddle}${lastOne}`;
  },
  getLevelColor(str) {
    if (str == '青铜') {
      return 'F28C2A'
    } else if (str == '白银') {
      return '94B9D9'
    }
    else if (str == '黄金') {
      return 'FB880C'
    } else if (str == '钻石') {
      return '51A5E8'
    } else if (str == '大师') {
      return '6D5CEC'
    } else if (str == '王者') {
      return '2F2E31'
    }

  },
  // 替换邮箱字符
  regEmail(email) {
    if (String(email).indexOf('@') > 0) {
      let str = email.split('@'),
        _s = ''
      if (str[0].length > 3) {
        for (let i = 0; i < str[0].length - 3; i++) {
          _s += '*'
        }
      }
      var new_email = str[0].substr(0, 3) + _s + '@' + str[1]
    }
    return new_email
  }
};


