/*
 * @FilePath: /daren-html-source/src/utils/apiList/offlineRebate.js
 * @Description:  优惠码接口 下线返利接口
 */
import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";
var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥
function decrypt(data) {
    if (data?.ak) {
        let plaintext = utils.rd(data.ak, unescape(sfile));
        let obj = utils.secret(data.ed, plaintext, true);
        return JSON.parse(obj);
    } else {
        return data;
    }
}
function inParameter(data) {
    if (data) {
        return { sn: ed, jt: utils.secret(data, ak) };
    } else {
        return data;
    }
}
//获取邀请码链接
export function invitedDiscountCodeAPI(data) {
    return request({
        url: "/cash/invitedDiscountCode",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//获取等级收益
export function invitedGradeAPI(data) {
    return request({
        url: "/cash/invitedGrade",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//返利列表（优惠码底部列表接口）
export function invitedMemberListAPI(data) {
    return request({
        url: "/cash/invitedMemberList",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//下线返利接口（下线返利底部列表接口）
export function inviteNumPeopleAPI(data) {
    return request({
        url: "/cash/inviteNumPeople",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//获取达人等级列表
export function planInfoAPI(data) {
    return request({
        url: "/helpCenter/planInfo",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//获取收益模式对比
export function fileModelInfoAPI(data) {
    return request({
        url: "/helpCenter/fileModelInfo",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
