<!--
 * @FilePath: /daren-html-source/src/views/Help/fmp.vue
 * @Description: help 飞猫盘tab
-->
<template>
  <div class="fmp">
    <div class="pub-tit0">收益简介</div>
    <div class="intrBox">
      <div class="one">
        <span class="c2c"
          >分享文件给其他用户下载/转存你就能获得收益，每日所得收益次日凌晨自动结算。</span
        >
      </div>
      <div class="one">
        <span class="c2c">下载量返利模式：</span>区分大小文件按
        <span class="c2c">下载量返利</span>，区分等级万次下载最高<span
          class="c2c"
          >1888元</span
        >，每周三、周四<span class="c2c">双倍</span>收益。
      </div>
      <div class="one">
        <span class="c2c">会员返利模式：</span
        >不按下载次数计费，只根据下载用户开通会员进行按
        <span class="c2c">比例分成</span>，文件<span class="c2c">不过期</span
        >，页面更简洁，适合<span class="c2c">大文件</span>分享。
      </div>
    </div>
    <div v-show="$store.state.userInfo.daren_rebate_switch == 1">
      <div class="pub-tit0 tit">收益模式对比</div>
      <div class="modeTable">
        <div class="modeHead">
          <div class="mhead0">权益</div>
          <div class="mhead1">
            下载量返利模式
            <img class="imgicon icon0" :src="$utils.getPng('icon8')" alt="" />
          </div>
          <div class="mhead1">
            会员返利模式
            <img class="imgicon icon1" :src="$utils.getPng('icon7')" alt="" />
          </div>
          <div class="mhead2">更多模式即将上线</div>
        </div>
        <div class="modeContent" v-loading="loadingFlag">
          <div class="colum0">
            <div class="item" v-for="(item, index) in list[0]" :key="index">
              {{ item.value_1 }}
            </div>
          </div>
          <div class="colum1">
            <div
              class="mhead1 item1"
              v-for="(item, index) in list[1]"
              :key="index"
            >
              <div class="intrBox1" v-if="item.type == 1">
                <div class="intr1">{{ item.value_1 }}</div>
                <div class="intr2" v-if="item.value_2">{{ item.value_2 }}</div>
              </div>
              <img
                v-if="item.type == 2"
                class="icon_check"
                :src="$utils.getPng('icon_check')"
                alt=""
                srcset=""
              />
              <div class="intrBox1" v-if="item.length == 0">
                <div class="intr1">-</div>
              </div>
            </div>
          </div>
          <div class="colum1">
            <div
              class="mhead1 item1"
              v-for="(item, index) in list[2]"
              :key="index"
            >
              <div class="intrBox1" v-if="item.type == 1">
                <div class="intr1">{{ item.value_1 }}</div>
                <div class="intr2" v-if="item.value_2">{{ item.value_2 }}</div>
              </div>
              <div class="intrBox1" v-if="item.length == 0">
                <div class="intr1">-</div>
              </div>
              <img
                v-if="item.type == 2"
                class="icon_check"
                :src="$utils.getPng('icon_check')"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div class="colum1">
            <div
              class="mhead2 item1"
              v-for="(item, index) in list[2]"
              :key="index"
            >
              <div class="intrBox1">
                <div class="intr1">-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pub-tit0" style="margin-top: 52px">下载量返利模式规则</div>
    <div class="intrBox">
      <div class="one">
        <span class="c2c">1、返利机制：</span
        >按当日达人等级与当日所产生的下载量，在次日凌晨自动结算返利收益金额。
      </div>
      <div class="one">
        <span class="c2c"> 2、等级规则：</span
        >根据昨日所产生的下载量来确定该模式下的达人返利等级，例如昨日下载量达到302，则今日达人等级为黄金。
      </div>
      <div class="one">
        <span class="c2c"> 3、双倍收益：</span
        >每周三、周四可获得双倍的下载量返利收益。
      </div>
    </div>
    <LevelTable style="margin-top: 20px"></LevelTable>
    <div v-show="$store.state.userInfo.daren_rebate_switch == 1">
      <div class="pub-tit0" style="margin-top: 52px" @click="toTop">
        会员返利模式规则
      </div>
      <div class="intrBox">
        <div class="one">
          <span class="c2c">1、返利机制：</span
          >选择会员返利模式后，其他用户通过你分享的下载链接访问，在下载或转存文件后，将与你建立24小时的绑定关系。
          在绑定期内，若用户在网页端或APP端上开通会员，你将获得其会员费用的{{
            open_vip_rebate
          }}作为返利。
        </div>
        <div class="one">
          <span class="c2c"> 2、多次购买返利：</span
          >在24小时绑定期内，用户若多次开通会员，每次开通会员金额的{{
            open_vip_rebate
          }}均将作为您的返利。
        </div>

        <div class="one">
          <span class="c2c">3、绑定关系限制：</span
          >一旦用户与您建立了绑定关系，他们在24小时内将无法与其他达人建立新的绑定关系。
        </div>

        <div class="one">
          <span class="c2c">4、优惠码返利限制：</span
          >如果用户在开通会员时使用了优惠码，平台将根据优惠码的使用情况，向你发放相应的返利收益。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LevelTable from "@/components/LevelTable/index.vue";
import { fileModelInfoAPI } from "@/utils/apiList/offlineRebate";
export default {
  components: {
    LevelTable,
  },
  data() {
    return {
      list: [],
      open_vip_rebate: "30%",
      loadingFlag: false,
    };
  },
  created() {
    this.fileMode();
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    fileMode() {
      this.loadingFlag = true;
      fileModelInfoAPI().then((res) => {
        this.loadingFlag = false;
        if (res.status == 1) {
          this.list = res.data.list;
          this.open_vip_rebate = res.data.open_vip_rebate;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fmp {
  .tit {
    margin: 52px 0 15px 0;
  }
  .modeTable {
    display: flex;
    flex-direction: column;

    // overflow: hidden;
    .mhead0 {
      width: 188px;
      height: 22px;
      font-weight: 500;
      font-size: 14px;
      color: #8a8b92;
    }
    .mhead1 {
      width: 282px;
      font-weight: 500;
      font-size: 14px;
      color: #2c2c2c;
      position: relative;
      text-align: center;
      .imgicon {
        position: absolute;
        top: -21px;
      }
    }
    .mhead2 {
      width: 132px;
      font-weight: 500;
      font-size: 14px;
      color: #2c2c2c;
      text-align: left;
    }
    .modeHead {
      width: 932px;
      height: 52px;
      border-radius: 12px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      background: #f9f9f9;
      .icon0 {
        width: 52px;
        height: 24px;
      }
      .icon1 {
        width: 52px;
        height: 24px;
      }
    }
    .modeContent {
      display: flex;
      min-height: 400px;

      .colum0 {
        display: flex;
        flex-direction: column;
        width: 188px;
        margin-left: 24px;
        flex-shrink: 0;
        .item {
          height: 64px;
          line-height: 64px;
          box-shadow: inset 0px -1px 0px 0px #f6f6f6;
        }
      }
      .colum1 {
        display: flex;
        flex-direction: column;
        .item1 {
          height: 64px;
          line-height: 64px;
          box-shadow: inset 0px -1px 0px 0px #f6f6f6;
          color: #8a8b92;
          display: flex;
          align-items: center;
          justify-content: center;
          .intrBox1 {
            display: flex;
            flex-direction: column;
            .intr1 {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
            }
            .intr2 {
              height: 20px;
              font-size: 12px;
              line-height: 20px;
            }
          }
          .icon_check {
            width: 20px;
            height: 20px;
          }
        }
        .item2 {
          color: #8a8b92;
          text-align: center;
        }
      }
    }
  }
}
</style>
