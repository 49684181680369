<!--
 * @FilePath: /daren-html-source/src/views/Help/rebate.vue
 * @Description: 下线返利tab
-->
<template>
  <div class="rebate">
    <div class="pub-tit0">什么是下线返利？</div>
    <div class="intrBox">
      <div class="one">用户A邀请用户B注册，则用户B成为用户A的下线：</div>
      <div class="one">
        平台按用户B在飞猫盘推广活动中获得收益的15%额外给用户A奖励，持续有效。
      </div>

    </div>
    <div class="pub-tit0" style="margin: 52px 0 20px 0">达人等级</div>
    <LevelTabe></LevelTabe>
  </div>
</template>

<script>
import LevelTabe from "@/components/LevelTable/index.vue";
export default {
  components: {
    LevelTabe,
  },
};
</script>

<style lang="scss" scoped>
.rebate {
  padding-bottom: 100px;
}
</style>
