<!--
 * @FilePath: /daren-html-source/src/views/Account/components/tab0.vue
 * @Description: 可提现金额
-->
<template>
  <div class="tab0" v-if="!loading">
    <div class="item" style="height: 44px; align-items: flex-end">
      <div class="itemLeft" style="margin-bottom: 5px" >可提现金额</div>
      <div class="itemRight">
        <div class="moneyBox">
          {{ $store.state.userInfo.wealth }}<span class="moneyUnit">元</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="itemLeft">收款人</div>
      <div class="itemRight" v-if="accountList.length === 0">
        <fm-button
          @click="showAddpayFlag = true"
          icon="icon-add"
          :dashed="true"
          plain
          size="medium"
          >新增收款人</fm-button
        >
      </div>
      <div class="itemRight" v-else>
        <el-select
          class="addplayer"
          style="width: 320px"
          v-model="form.accountId"
          placeholder="请选择"
          @change="playerChange"
        >
          <el-option
            v-for="item in accountList"
            :key="item.id"
            :label="
              item.income_account +
              ' 支付宝 ' +
              item.income_name +
              ' 额度 ' +
              item.total
            "
            :value="item.id"
          >
            <div class="one">
              <span>{{ item.income_account }}</span> &nbsp;
              <span>支付宝</span> &nbsp;
              <span>{{ item.income_name }}</span> &nbsp;
              <span>额度</span> &nbsp;
              <span>{{ item.total }}</span>
            </div>
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item" style="margin-bottom: 36px">
      <div class="itemLeft">提现金额</div>
      <div class="itemRight">
        <el-input
          style="width: 320px"
          v-model="form.money"
          type="number"
          maxlength="2"
          placeholder="请输入提现金额"
        >
        </el-input>
        <div class="tit">每笔提现最低30元</div>
        <div class="unit">元</div>
      </div>
    </div>
    <!-- style="margin-bottom: 36px"  展示 输入框的时候 需要加上margin -->
    <div class="item" style="margin-bottom: 36px">
      <div class="itemLeft">手机号</div>
      <div class="itemRight" v-if="this.$store.state.userInfo.phone">
        <div class="phone">
          {{ $utils.hidePhone(form.phone) }}
        </div>
      </div>
      <div class="itemRight pcodeBox" v-else>
        <div class="pcode">+86</div>
        <el-input
          style="width: 320px"
          :maxlength="11"
          v-model="form.phone"
          placeholder="请输入手机号"
        >
        </el-input>
        <div class="tit">
          * 您尚未进行手机绑定，提现成功后将默认绑定此手机号
        </div>
      </div>
    </div>
    <div class="item">
      <div class="itemLeft">输入验证码</div>
      <div class="itemRight">
        <div class="sendCode">
          <el-input
            style="width: 320px"
            v-model="form.qcode"
            placeholder="请输入验证码"
            @keyup.enter.native="toSendcode()"
          >
          </el-input>
          <fm-button
            @click="time == '60' ? toSendcode() : ''"
            :disabled="time == '60' && form.phone.length == 11 ? false : true"
            style="margin-left: 12px; width: 108px"
            size="medium"
            >{{
              time == "60" ? "获取验证码" : "已发送(" + time + "s)"
            }}</fm-button
          >
        </div>
      </div>
    </div>
    <div class="item">
      <div class="itemLeft"></div>
      <div class="itemRight">
        <fm-button
          style="width: 164px"
          type="primary"
          round
          size="big"
          @click="getCash()"
          >立即提现</fm-button
        >
      </div>
    </div>
    <AddPayee :isShow="showAddpayFlag" />
  </div>
</template>

<script>
import AddPayee from "@/components/AddPayee/index.vue";
import { getPayeeListAPI, sendCodeAPI, toCashAPI } from "@/utils/apiList/user";
export default {
  components: {
    AddPayee,
  },
  data() {
    return {
      keyword: "",
      disabled: false,
      timerA: "",
      time: 60,
      showAddpayFlag: false,
      form: {
        accountId: "",
        money: "",
        phone: "",
        qcode: "",
        smsid: "",
      },
      accountList: [],
      loading: false,
    };
  },
  created() {
    this.getPayeeList();
    this.form.phone = this.$store.state.userInfo.phone;
  },
  beforeDestroy() {
    this.time = 60;
    this.timerA && clearInterval(this.timerA);
  },
  methods: {
    toSendcode: _.debounce(
      function (captcha_verify) {
        return new Promise((resolve) => {
          if (this.time != 60) {
            return;
          }
          var reg_tel =
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
          if (!reg_tel.test(this.form.phone)) {
            this.$toast("请正确输入手机号", 3);
            return true;
          }
          this.timerA && clearInterval(this.timerA);

          sendCodeAPI({
            pcode: "+86",
            phone: this.form.phone,
            captcha_verify: captcha_verify || "",
          })
            .then((res) => {
              if (res.status == 1) {
                resolve({
                  result: true,
                  validate: true,
                });
                this.form.smsid = res.data.msgid;
                this.timerA = setInterval(() => {
                  this.minute();
                }, 1000);
                this.$toast(res.msg, 1);

                this.$toast(res.msg, 1);
              } else if (res.status == 90001) {
                resolve({
                  result: false,
                  validate: false,
                });
                this.$toast(res.msg, 2);
                this.$AliCode.handleOpen(this.toSendcode);
              } else {
                resolve({
                  result: true,
                  validate: true,
                });
                this.$toast(res.msg, 2);
              }
            })
            .catch((err) => {});
        });
      },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    getPayeeList() {
      this.loading = true;
      getPayeeListAPI({ page: 1, limit: 100 }).then((res) => {
        if (res.status == 1) {
          this.accountList = res.data.accountList.list;
          this.loading = false;
        }
      });
    },
    playerChange(item) {
      this.form.accountId = item;
    },
    getCash() {
      if (this.form.accountId == "") {
        this.$toast("请选择收款人", 2);

        return;
      }
      if (this.form.money === "") {
        this.$toast("提现金额不能为空", 2);
        return;
      }
      var re = /^[0-9]+$/;
      if (!re.test(this.form.money)) {
        this.$toast("提现金额只能是正整数", 2);

        return;
      }
      if (parseInt(this.form.money) < 30) {
        this.$toast("提现金额不能低于30", 2);
        return;
      }
      if (this.verificationCode === "") {
        this.$toast("请输入验证码", 4);
        return;
      }
      toCashAPI(this.form)
        .then((res) => {
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            setTimeout(() => {
              // window.location.reload();
              this.$store.commit("getUserInfoVuex");
              this.resert()
            }, 2000);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    minute: function () {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    resert() {
      this.form = {
        accountId: "",
        money: "",
        phone: this.$store.state.userInfo.phone,
        qcode: "",
        smsid: "",
      };
      this.time = 60;
      this.timerA && clearInterval(this.timerA);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab0 {
  padding: 68px 110px 0 110px;
  font-weight: 400;
  font-size: 16px;
  color: #2c2c2c;

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    .itemLeft {
      width: 84px;
      text-align: right;

      margin-right: 24px;
    }
    .itemRight {
      position: relative;
      .moneyBox {
        font-weight: 600;
        font-weight: 500;
        font-size: 36px;
        color: #f67942;
      }

      .moneyUnit {
        font-weight: 400;
        font-size: 16px;
        color: #2c2c2c;
        margin-left: 4px;
      }

      .tit {
        height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #f53f3f;
        line-height: 20px;
        position: absolute;
        bottom: -20px;
        left: 0;
      }
      .unit {
        position: absolute;
        top: 50%;
        right: 12px;
        font-weight: 400;
        font-size: 14px;
        color: #bdbdbe;
        transform: translateY(-50%);
      }

      .pcode {
        position: absolute;
        top: 50%;
        left: 12px;
        font-weight: 400;
        font-size: 14px;
        z-index: 100;
        transform: translateY(-50%);
      }

      .phone {
        height: 24px;
        font-weight: 500;
        font-size: 16px;
        color: #2c2c2c;
        line-height: 24px;
      }
      .sendCode {
        display: flex;
        align-items: center;
      }
    }
    .pcodeBox {
      ::v-deep .btn-prev {
        display: none;
      }
      ::v-deep .el-input .el-input__inner {
        padding-left: 54px;
      }
    }
  }
}
</style>
