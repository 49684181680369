<!--
 * @FilePath: /daren-html-source/src/views/FmCloud/index.vue
 * @Description: 飞猫盘
-->
<template>
  <div class="fmcloud">
    <div class="fmTop">
      <div class="fmtit">
        <span class="tit">下载量返利模式</span>
        <span class="tips"
          >（您有{{
            Intl.NumberFormat("en-US").format(downTable.file_count)
          }}个文件使用该模式）</span
        >
      </div>
      <div class="levelBox">
        我的等级：<span
          class="tips"
          :style="`color:#${$utils.getLevelColor(
            $store.state.userInfo.user_plan.subject
          )}`"
          >{{ $store.state.userInfo.user_plan.subject }}</span
        >
      </div>
    </div>
    <div class="intr">
      区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益，<span
        class="tips pointer"
        style="font-weight: bold"
        @click="toplay(0)"
        >详细介绍</span
      >
    </div>

    <div class="echartBox">
      <div id="echart1" style="width: 932px; height: 405px"></div>
    </div>
    <!-- 暂未获奖 进行中 -->
    <div class="dayNum">
      <div class="item" v-for="item in downNumList" :key="item.id">
        <div class="daytit">{{ item.name }}</div>
        <div class="dayDaren" v-if="item.id == 2">
          <span class="status1" v-if="item.task_status == 1">暂未获奖</span>
          <fm-button
            class="btn"
            v-else
            @click="toRank(item)"
            :disabled="item.task_status == 3"
            type="primary"
            size="mini"
            >{{ item.task_status == 2 ? "领取奖励" : "已领取" }}</fm-button
          >
        </div>
        <div class="dayNumber" v-else>
          {{ Intl.NumberFormat("en-US").format(item.num) }}
        </div>
        <div class="dayIntr">
          {{ item.id == 2 ? "每周三评奖" : auto_cash_update_info }}
        </div>
      </div>
    </div>
    <div v-show="$store.state.userInfo.daren_rebate_switch == 1">
      <div class="fmTop" style="margin-top: 64px">
        <div class="fmtit">
          <span class="tit">会员返利模式</span>
          <span class="tips"
            >（您有{{ Intl.NumberFormat("en-US").format(darenTable.file_count) }}个文件使用该模式）</span
          >
        </div>
      </div>
      <div class="intr">
        不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁，适合大文件分享，<span
          class="tips pointer"
          style="font-weight: bold"
          @click="toplay(0)"
          >详细介绍</span
        >
      </div>
      <div class="echartBox">
        <div id="echart2" style="width: 932px; height: 405px"></div>
      </div>

      <div class="dayNum">
        <div class="item" v-for="item in memberList" :key="item.id">
          <div class="daytit">{{ item.name }}</div>
          <div class="dayNumber">
            {{ Intl.NumberFormat("en-US").format(item.num) }}
          </div>
          <div class="dayIntr">{{ auto_cash_update_info }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  creditDataRendAPI,
  creditDataDarenRendAPI,
  cloudToTaskAPI,
} from "@/utils/apiList/fmp";
export default {
  data() {
    return {
      downNumList: [
        {
          id: 0,
          name: "今日大于100M文件下载量（次）",
          num: 0,
        },
        {
          id: 1,
          name: "今日小于100M文件下载量（次）",
          num: 0,
        },
        {
          id: 2,
          name: "每周飞猫达人奖",
          num: 0,
          task_status: 1,
          taskId: 0,
        },
      ],
      memberList: [
        {
          id: 0,
          name: "今日返佣个数",
          num: 0,
        },
        {
          id: 1,
          name: "今日返佣金额",
          num: 0,
        },
      ],

      downTable: {
        x: [],
        ymin: [],
        ymax: [],
        file_count: 0,
      },
      darenTable: {
        x: [],
        ymin: [], //返佣个数
        ymax: [], //返佣金额
        file_count: 0,
      },
      auto_cash_update_info: "次日上午8点自动兑换到余额",
    };
  },
  mounted() {
    this.getData1();
    this.getData2();
  },
  methods: {
    initEchart1() {
      var chartDom = document.getElementById("echart1");
      var myChart = echarts.init(chartDom);
      var option;

      (option = {
        legend: {
          data: [
            {
              name: "大文件收益",
              icon: "circle",
            },
            {
              name: "小文件收益",
              icon: "circle",
            },
          ],
          bottom: 0,
        },
        tooltip: {
          // 设置tip提示
          trigger: "axis",
          extraCssText:
            "box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.06);border-radius: 8px;",
        },
        grid: {
          // 调整图表上下左右位置
          left: 0,
          right: 30,
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
               color: "#8a8b92", // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },
        xAxis: {
          // 设置x轴
          type: "category",
          data: this.downTable.x,

          axisLabel: {
            show: true,
            margin: 12,
            textStyle: {
               color: "#8a8b92", // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },

        series: [
          {
            name: "大文件收益",
            data: this.downTable.ymax,
            type: "line",
            symbolSize: 2,
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255,231,220,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255,255,255,0)",
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: "rgba(246, 121, 66, 1)", // 改变折线点的颜色
                lineStyle: {
                  color: "rgba(246, 121, 66, 1)", // 改变折线颜色
                  width: 2, // 设置线条粗细
                },
              },
            },
          },
          {
            name: "小文件收益",
            data: this.downTable.ymin,
            type: "line",
            smooth: true,
            symbolSize: 2,

            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(220,235,255,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255,255,255,0)",
                },
              ]),
            },
          },
        ],
      }),
        option && myChart.setOption(option);
    },
    initEchart2() {
      var chartDom = document.getElementById("echart2");
      var myChart = echarts.init(chartDom);
      var option;

      (option = {
        legend: {
          data: [
            {
              name: "返佣个数",
              icon: "circle",
            },
            {
              name: "返佣金额",
              icon: "circle",
            },
          ],
          bottom: 0,
        },
        tooltip: {
          // 设置tip提示
          trigger: "axis",
          extraCssText:
            "box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.06);border-radius: 8px;",
        },
        grid: {
          // 调整图表上下左右位置
          left: 0,
          right: 30,
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8a8b92", // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },
        xAxis: {
          // 设置x轴
          type: "category",
          data: this.darenTable.x,

          axisLabel: {
            show: true,
            margin: 12,
            textStyle: {
               color: "#8a8b92",// 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },

        series: [
          {
            name: "返佣个数",
            data: this.darenTable.ymin,
            type: "line",
            symbolSize: 2,
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255,231,220,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255,255,255,0)",
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: "rgba(246, 121, 66, 1)", // 改变折线点的颜色
                lineStyle: {
                  color: "rgba(246, 121, 66, 1)", // 改变折线颜色
                  width: 2, // 设置线条粗细
                },
              },
            },
          },
          {
            name: "返佣金额",
            data: this.darenTable.ymax,
            type: "line",
            smooth: true,
            symbolSize: 2,

            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(220,235,255,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255,255,255,0)",
                },
              ]),
            },
          },
        ],
      }),
        option && myChart.setOption(option);
    },
    toplay(id) {
      this.$router.push({ path: "/help", query: { id: id } });
    },
    getData1() {
      creditDataRendAPI()
        .then((res) => {
          if (res.status == 1) {
            this.downTable.x = res.data.date_list;
            this.downTable.ymin = res.data.list.map((item) => item.nums1);
            this.downTable.ymax = res.data.list.map((item) => item.nums2);
            this.downTable.file_count = parseInt(res.data.file_count);
            this.initEchart1();
            this.downNumList[1].num = res.data.lower_lewltotal;
            this.downNumList[0].num = res.data.over_lewltotal;
            this.downNumList[2].task_status = res.data.task.task_status;
            this.downNumList[2].taskId = res.data.task.tid;
            this.auto_cash_update_info = res.data.auto_cash_update_info;
          }
        })
        .catch((err) => {});
    },
    getData2() {
      creditDataDarenRendAPI()
        .then((res) => {
          if (res.status == 1) {
            this.darenTable.x = res.data.date_list;
            this.darenTable.ymin = res.data.list.map((item) => item.nums1);
            this.darenTable.ymax = res.data.list.map((item) => item.nums2);
            this.darenTable.file_count = parseInt(res.data.file_count);

            this.initEchart2();
            //个数
            this.memberList[1].num = res.data.lower_lewltotal;
            //金额
            this.memberList[0].num = res.data.over_lewltotal;
          }
        })
        .catch((err) => {});
    },
    toRank(item) {
      if (item.task_status == 3) {
        return;
      }
      cloudToTaskAPI().then((res) => {
        if (res.status == 1) {
          this.getData2();
          this.getData1();
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
