<!--
 * @FilePath: /daren-html-source/src/views/IntrDaren/index.vue
 * @Description: 达人开通页面 
-->
<template>
  <div class="intrDaren">
    <div class="intrTop">
      <img class="llz_left" :src="$utils.getPng('llz_left')" alt="" />
      <div class="box_right">
        <div class="rname">飞猫达人</div>
        <div class="rname1">开通成为飞猫达人后即可解锁多项推广返利功能！</div>
        <div class="rbtn pointer" @click="openDaren()">立即开通</div>
      </div>
    </div>
    <div class="intrCenter">
      <div class="intrTit">平台玩法</div>
      <div class="payTable">
        <div class="item" v-for="item in payList" :key="item.id">
          <img class="icon" :src="$utils.getPng('icon' + item.id)" alt="" />
          <div class="tit">{{ item.tit }}</div>
          <div class="introduce">{{ item.introduce }}</div>
        </div>
      </div>
    </div>
    <div class="intrDown">
      <div class="intrTit">活动规则</div>
      <div class="rulesList">
        <div class="item">
          <div class="num">01</div>
          <div class="tit1">申请人需通过实名认证</div>
          <img class="icon3" :src="$utils.getPng('icon3')" />
        </div>
        <div class="item" style="margin-left: 40px">
          <div class="num">02</div>
          <div class="tit1">
            需遵守平台规则
            <span class="linkpublic pointer" @click="toDetail"
              >《飞猫盘用户服务协议》</span
            >
          </div>
          <img class="icon3" :src="$utils.getPng('icon3')" />
        </div>
      </div>
    </div>
    <Step1
      :isShow="showStep1"
      @confirm="opDaren()"
      @tokefu="tokefu()"
      @handleClose="closeStep1()"
    ></Step1>
    <Step2
      :isShow="showStep2"
      @confirm="opDaren()"
      @handleClose="closeStep2()"
    ></Step2>
    <RealName
      :isShow="showRealNameFlag"
      :type="2"
      @handleClose="showRealNameFlag = false"
      @confirmNameAuth="realNameAuth"
    ></RealName>
    <ChangePhoneDiaVue
      ref="ChangePhoneDia"
      :userInfo="userInfo"
      :showChangePhoneFlag="showChangePhoneFlag"
      @handleClose="showChangePhoneFlag = false"
    ></ChangePhoneDiaVue>
  </div>
</template>

<script>
import { openDarenAPI } from "@/utils/apiList/user";
import ChangePhoneDiaVue from "@/components/ChangePhoneDIa/index.vue";

import Step1 from "@/components/OpenDarenDia/step1.vue";
import Step2 from "@/components/OpenDarenDia/step2.vue";
import { userInfoAPI, realNameAuth } from "@/utils/apiList/user.js";
import RealName from "@/components/ChangePhoneDIa/realName.vue";
export default {
  components: { Step1, RealName, Step2, ChangePhoneDiaVue },
  data() {
    return {
      payList: [
        {
          id: 0,
          tit: "飞猫盘返利",
          introduce: "分享文件给其他用户下载/转存获得收益",
        },
        {
          id: 1,
          tit: "优惠码返利",
          introduce: "用户通过优惠码开通会员获得返利收益",
        },
        {
          id: 2,
          tit: "下线返利",
          introduce: "邀请好友成为飞猫达人获得好友的收益分成",
        },
      ],
      showStep1: false,
      showStep2: false,
      userInfo: {},
      showRealNameFlag: false,
      showChangePhoneFlag: false,
    };
  },
  created() {
    if (this.$utils.getCookie(this.$glb.fmCookieName)) {
      this.getUser();
    }
    let flag = this.$route.params.flag;
    if (flag == 0) {
      this.showRealNameFlag = true;
    } else if (flag == 2) {
      this.$nextTick(() => {
        this.$refs.ChangePhoneDia.step = 2;
        this.$refs.ChangePhoneDia.topTit = "绑定手机号";
        this.showChangePhoneFlag = true;
      });
    }
  },
  methods: {
    openDaren() {
      if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
        const from = "daren";
        const fromUrl = this.$glb.darenUrl + `/llz`;
        const encodedFrom = encodeURIComponent(from);
        const encodedFromUrl = encodeURIComponent(fromUrl);
        window.location.href =
          this.$glb.fmpUrl +
          `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
        return;
      }
      if (this.userInfo.phone == "") {
        this.$refs.ChangePhoneDia.step = 2;
        this.$refs.ChangePhoneDia.topTit = "绑定手机号";
        this.showChangePhoneFlag = true;
        return;
      }
      if (this.userInfo.is_bind_idcard == 0) {
        this.showRealNameFlag = true;
        return;
      }
      openDarenAPI()
        .then((res) => {
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            this.getUser();
            localStorage.setItem("isDaren", 1);
            setTimeout(() => {
              this.$router.replace("/drive");
            }, 100);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    getUser() {
      userInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.userInfo = res.data;
            this.$store.commit("setAttr", {
              name: "userInfo",
              val: res.data,
            });
            localStorage.setItem("isDaren", res.data.is_rate_flow);
            if (res.data.is_rate_flow != 1) {
              if (sessionStorage.getItem("isFirst") == 1) {
                this.showStep1 = true;
              }
            } else {
              this.$router.replace("/drive");
            }
            sessionStorage.setItem("isFirst", 0);
          }
        })
        .catch((err) => {});
    },
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.showRealNameFlag = false;
          this.getUser();
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    closeStep1() {
      this.showStep1 = false;
      this.showStep2 = true;
    },
    closeStep2() {
      this.showStep2 = false;
    },
    opDaren() {
      this.showStep1 = false;
      this.showStep2 = false;
      if (this.userInfo.phone == "") {
        this.$refs.ChangePhoneDia.step = 2;
        this.$refs.ChangePhoneDia.topTit = "绑定手机号";
        this.showChangePhoneFlag = true;
        return;
      }

      if (this.userInfo.is_bind_idcard == 0) {
        this.showRealNameFlag = true;
      }
    },
    tokefu() {
      this.showStep1 = false;
      window.open("http://q.url.cn/CDKEvl?_type=wpa&qidian=true");
    },
    toDetail() {
      window.open(`${this.$glb.fmpUrl}/askdetail/91`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
